@charset "utf-8";
/* Slider */
.slick-loading .slick-list {
	background: #fff url('./ajax-loader.gif') center center no-repeat;
}
.slider a{
	display:block;	
}
/* Icons */
@font-face {
	font-family: 'slick';
	font-weight: normal;
	font-style: normal;
	src: url('./fonts/slick.eot');
	src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev, .slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 38%;
	display: block;
	width: 46px;
	height: 46px;
	padding: 0;
	cursor: pointer;
	outline: none;
	border: none;

}
.slick-prev {
	background: url(../img/home/btn_prev.png) no-repeat center;
	background-size:100%;
}
.slick-next {
	background: url(../img/home/btn_next.png) no-repeat center;
	background-size:100%;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {

}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
	opacity: 1;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
	opacity: .25;
}
.slick-prev:before, .slick-next:before {
	font-family: 'slick';
	font-size: 20px;
	line-height: 1;
	opacity: .75;
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.slick-prev {
	left: -50px;
	z-index: 10;
}

.slick-next {
	right: -50px;
	z-index: 10;
}

/* Dots */
.slick-slider {
	margin-bottom: 0px;
}
.slick-dots {
	position: absolute;
	bottom: -30px;
	display: block;
	width: 100%;
	padding: 0 ;
	list-style: none;
	text-align: center;
}
.multiple-item .slick-dots {
	text-align:center;
	bottom:-28px;	
	padding:0px;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	margin: 0 2.5px;
	padding: 0;
	cursor: pointer;
	z-index: 1000;
}
.slick-dots li button {
	border-radius: 50%;
	font-size: 0;
	line-height: 0;
	display: block;
	width: 10px;
	height: 10px;
	cursor: pointer;
	border: 0;
	outline: none;
	background-color: rgba(256,256,256,1);
	padding:0;
}
.slick-dots li button:hover, .slick-dots li button:focus {
	outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
	opacity: 1;
}
.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '窶｢';
	text-align: center;
	opacity: .25;
	color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display:none;
}
.slick-dots li.slick-active button:before {
	opacity: .75;
	color: black;
}
.slick-dots li.slick-active button {
    background: #0D8BD1;
}

@media screen and (max-width: 800px) {
	.slick-dots {
	position: absolute;
	bottom: 10px;
	display: block;
	width: 100%;
	padding: 0 10px 0 0;
	list-style: none;
	text-align: right;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	width: 6px;
	height: 6px;
	margin: 0 2px;
	padding: 0;
	cursor: pointer;
	z-index: 1000;
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 6px;
	height: 6px;
	padding: 3px;
	cursor: pointer;
	border: 0;
	outline: none;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 5px;
	background:#ccc;
}
.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 12px;
	height: 12px;
	content: '窶｢';
	text-align: center;
	opacity: .25;
	color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.banner .slick-dots {
	position: absolute;
	bottom: -5px;
	display: block;
	width: 100%;
	padding: 0 10px 0 0;
	list-style: none;
	text-align: center;
}
}